import { Link } from "gatsby"
import React from "react"

// @ts-ignore
import AppStoreIcon from "../../assets/Download_on_the_App_Store_Badge_US-UK_RGB_blk_092917.svg"
// @ts-ignore
import GooglePlayStoreIcon from "../../assets/google-play-store.svg"
import ArrowForward from "@material-ui/icons/ArrowForward"
import { Message } from "./types"
import { Locale } from "../../common/appContext"
import { OutboundLink } from "gatsby-plugin-google-gtag-optin"

type Props = {
  includeMarketPlace?: boolean
  messages: Message[]
  locale?: Locale
}

const StoreBanners = ({
  includeMarketPlace = false,
  messages,
  locale = Locale.en,
}: Props) => {
  const downloadTheApp: string =
    messages.find(m => m.identifier === "downloadTheApp")?.message ?? ""
  const downloadTheAppBody: string =
    messages.find(m => m.identifier === "downloadTheAppBody")?.message ?? ""
  const websiteMarketplace: string =
    messages.find(m => m.identifier === "websiteMarketplace")?.message ?? ""

  return (
    <div>
      <h3 className="text-center">{downloadTheApp}</h3>
      <p className="text-center m-0">{downloadTheAppBody}</p>

      <div className="d-flex justify-content-center flex-wrap">
        <a
          className="py-3 pe-3"
          target={"_blank"}
          href="https://apps.apple.com/se/app/cruto/id1589599376?itsct=apps_box_badge&amp;itscg=30200"
          style={{
            display: "inline-block",
            overflow: "hidden",
            opacity: 1,
          }}
        >
          <img
            height={40}
            alt={"Download on the App Store"}
            src={AppStoreIcon}
          />
        </a>

        <a
          className="py-3"
          target={"_blank"}
          style={{ opacity: 1 }}
          href="https://play.google.com/store/apps/details?id=io.cruto.app&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"
        >
          <img
            height={40}
            alt={"Get it on Google Play"}
            src={GooglePlayStoreIcon}
          />
        </a>
      </div>

      {includeMarketPlace && (
        <div className="pt-1">
          <OutboundLink href={"https://app.cruto.io"}>
            <div className="d-flex align-items-center justify-content-center">
              <p className="m-0">{websiteMarketplace}</p>
              <ArrowForward />
            </div>
          </OutboundLink>
        </div>
      )}
    </div>
  )
}

export default StoreBanners
