import React from "react"
import PictureTemplate from "../common/pictureTemplate"
import { PictureData } from "../../common/types/pictureData"
import Wave, { WaveType } from "../ui/wave"

type TopSectionProps = {
  heading: string
  body: {
    html: string
  }
  picture: PictureData
}

const TopSection = ({ heading, body, picture }: TopSectionProps) => {
  return (
    <div className="u-bg-orange pt-5">
      <section className="min-vh-85 d-flex align-items-center">
        <div className={"container"}>
          <div className="row">
            <div className="align-items-center col-12 col-md-6 d-flex order-last order-md-0 p-3">
              <div
                data-sal="slide-up"
                data-sal-delay="200"
                data-sal-easing="ease"
                className="text-center text-md-start pt-4 pt-md-0"
              >
                <h2 className={"u-c-white"}>{heading}</h2>
                <div
                  className="u-lead-md pt-3 u-c-white u-o-1"
                  dangerouslySetInnerHTML={{ __html: body.html }}
                />
              </div>
            </div>
            <div
              data-sal="fade"
              data-sal-delay="400"
              data-sal-easing="ease"
              className="col-12 col-md-6 justify-content-end py-10 d-none d-md-flex"
            >
              <div className="phone-shell-wrapper shadow-2">
                <div style={{ position: "relative" }} className="phone-shell">
                  <PictureTemplate
                    {...picture}
                    style={{ borderRadius: "2rem", width: "320px" }}
                  />

                  <span
                    className="u-bg-grey"
                    style={{
                      height: "3rem",
                      width: "4px",
                      display: "inline-block",
                      position: "absolute",
                      left: "-4px",
                      top: "6rem",
                      borderRadius: "1rem",
                    }}
                  />

                  <span
                    className="u-bg-grey"
                    style={{
                      height: "3rem",
                      width: "4px",
                      display: "inline-block",
                      position: "absolute",
                      left: "-4px",
                      top: "10rem",
                      borderRadius: "1rem",
                    }}
                  />

                  <span
                    className="u-bg-grey"
                    style={{
                      height: "5rem",
                      width: "4px",
                      display: "inline-block",
                      position: "absolute",
                      right: "-4px",
                      top: "7rem",
                      borderRadius: "1rem",
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Wave className={"u-c-white"} type={WaveType.v1} />
    </div>
  )
}

export default TopSection
