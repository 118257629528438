import * as React from "react"
import Layout from "../components/layout/layout"
import SEO from "../components/seo"
import { graphql, useStaticQuery } from "gatsby"
import CardsSection, { CardType } from "../components/common/cards/cardsSection"
import Wave, { WaveType } from "../components/ui/wave"
import { PageType } from "./about"
import TopSection from "../components/jobseeker/topSection"
import { PictureData } from "../common/types/pictureData"
import { BlogPostType } from "../components/blog/blogPostSection"
import { Locale } from "../common/appContext"
import Steps from "../components/common/Steps"
import { Message } from "../components/common/types"
import StoreBanners from "../components/common/storeBanners"

const IndexPage = ({ path }) => {
  const {
    graphCmsPage,
    graphCmsAsset,
    allGraphCmsCard,
    allGraphCmsBlogPost,
    allGraphCmsMessageV2,
  } = useStaticQuery(graphql`
    {
      graphCmsPage(id: { eq: "Page:ckr85ybqokp1g0b58y8kfc2ge:sv:PUBLISHED" }) {
        heading
        body {
          html
        }
        seo {
          metaTitle
          metaDescription
          ogImage {
            url
          }
          focusKeywords
        }
      }
      graphCmsAsset(
        id: { eq: "Asset:ckqxje87sygpj0c51467gbvf6:en:PUBLISHED" }
      ) {
        alt
        url
      }
      allGraphCmsCard(
        filter: {
          cardCategory: { category: { eq: "jobseeker" } }
          locale: { eq: sv }
        }
      ) {
        nodes {
          body
          heading
        }
      }

      allGraphCmsBlogPost(
        sort: { order: ASC, fields: createdAt }
        filter: {
          stage: { eq: PUBLISHED }
          blogCategories: {
            elemMatch: {
              id: { eq: "BlogCategory:ckqxj1yuwynwm0c089cux1w4m:PUBLISHED" }
            }
          }
          locale: { eq: sv }
        }
      ) {
        nodes {
          blogCategories {
            category
            color
            slug
          }
          body {
            html
          }
          heading
          summary
          slug
          picture {
            alt
            id
            url
            localFile {
              childrenImageSharp {
                gatsbyImageData(width: 320, layout: CONSTRAINED)
              }
            }
          }
        }
      }
      allGraphCmsMessageV2(
        filter: {
          locale: { eq: sv }
          messageCategory: { category: { eq: "promotionBanner" } }
        }
      ) {
        nodes {
          identifier
          message
        }
      }
    }
  `)

  const data: PageType = graphCmsPage
  const picture: PictureData = graphCmsAsset
  const cards: CardType[] = allGraphCmsCard.nodes
  const posts: BlogPostType[] = allGraphCmsBlogPost.nodes
  const messages: Message[] = allGraphCmsMessageV2.nodes

  return (
    <Layout
      initialLogoWhite
      initialTextColorThemeWhite
      locale={Locale.sv}
      path={path}
    >
      <SEO title="Home" meta={data.seo} />
      <div>
        <TopSection {...data} picture={picture} />

        <section>
          <div className="container py-5">
            <CardsSection cards={cards} />
          </div>

          <Wave className={"u-c-blue"} type={WaveType.v2} />
        </section>

        <section className="u-bg-blue">
          <Wave className={"u-c-white"} type={WaveType.v4} />
        </section>

        <section className="container">
          <Steps posts={posts} />
        </section>
        <div className="container py-10">
          <StoreBanners
            includeMarketPlace
            messages={messages}
            locale={Locale.sv}
          />
        </div>
      </div>
      <Wave className={"u-c-orange"} type={WaveType.v5} />
    </Layout>
  )
}

export default IndexPage
